import { useState, useRef } from 'react';
import useIsomorphicLayoutEffect from '../utils/useIsomorphicLayoutEffect.js';

// http://workers-playground-icy-pine-ac0b.fatiao.workers.dev/proxy/https://gist.github.com/strothj/708afcf4f01dd04de8f49c92e88093c3

function useResizeObserver(callback, target, depsArray = []) {
  const [targetClientRect, setTargetClientRect] = useState(null);
  const savedCallback = useRef(callback);
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  });
  useIsomorphicLayoutEffect(() => {
    const targetEl = target && 'current' in target ? target.current : document.documentElement;
    if (!targetEl) {
      return;
    }
    if (typeof ResizeObserver === 'function') {
      const observer = new ResizeObserver(entries => {
        savedCallback.current(entries);
      });
      observer.observe(targetEl);
      return () => {
        observer.disconnect();
      };
    } else {
      const saveTargetDimensions = () => {
        const currTargetRect = targetEl.getBoundingClientRect();
        if (currTargetRect.width !== (targetClientRect === null || targetClientRect === undefined ? undefined : targetClientRect.width) || currTargetRect.height !== targetClientRect.height) {
          savedCallback.current([{
            contentRect: currTargetRect
          }]);
        }
        setTargetClientRect(currTargetRect);
      };
      // eslint-disable-next-line github/prefer-observers
      window.addEventListener('resize', saveTargetDimensions);
      return () => {
        window.removeEventListener('resize', saveTargetDimensions);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target === null || target === undefined ? undefined : target.current, ...depsArray]);
}

export { useResizeObserver };
